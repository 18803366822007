@import "../../scss/variables";

._3desm {
    &__flat-navigator {
        $size: 60px;
        $size-lg: 90px;
        $border-radius: 10px;
        width: $size;
        backdrop-filter: blur(5px);
        display: flex;
        flex-direction: column;
        border-radius: $border-radius 0 0 $border-radius;
        @include box-shadow(2);

        @include breakpoint-up-lg {
            width: $size-lg;
        }

        @include mobile {
            flex-direction: row;
            width: 100%;
            backdrop-filter: none;
        }

        &__button {
            @include background-color(bg-navigator);
            width: 100%;
            height: $size;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 5px;
            cursor: pointer;
            transition: all 0.2s ease-out;

            @include breakpoint-up-lg {
                height: $size-lg;
            }

            @include mobile {
                width: unset;
                flex: 1 0 100% * (1/6);
                @include background-color(bg-navigator-mobile);
            }

            &--active {
                @include background-color(bg-navigator-active);

                @include mobile {
                    @include background-color(bg-navigator-active-mobile);
                }
            }

            @include hover {
                @include background-color(bg-navigator-active);

                @include mobile {
                    @include background-color(bg-navigator-active-mobile);
                }
            }

            &:first-child {
                border-radius: $border-radius 0 0 0;

                @include mobile {
                    border-radius: 0;
                }
            }

            &:last-child {
                border-radius: 0 0 0 $border-radius;

                @include mobile {
                    border-radius: 0;
                }
            }

            &:not(:first-child) {
                border-top-width: 1px;
                border-top-style: solid;
                @include border-side-color(top, grey-3);

                @include mobile {
                    border-top: none;
                    @include border-side(left, 1, grey-3);
                }
            }

            &-icon {
                @include ff-icon-1;
                font-size: 25px;

                @include breakpoint-up-lg {
                    font-size: 40px;
                }
            }

            &-title {
                text-align: center;
                font-size: 8px;
                text-transform: uppercase;
                font-weight: 700;
                padding-top: 5px;

                @include breakpoint-up-lg {
                    font-size: 10px;
                }
            }
        }
    }
}
