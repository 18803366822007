@import "../../scss/variables";

._3desm {
    &__compare-button {
        $size: 40px;
        width: $size;
        height: $size;
        border-radius: $size/2;
        @include ff-icon;
        @include background-color(bg);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        cursor: pointer;
        @include color(black-1);
        transition: all 0.1s ease-out;
        @include box-shadow(1);
        user-select: none;
        border: none;
        outline: none;

        @include hover {
            @include color(main);
        }

        &--small {
            $size: 30px;
            border-radius: $size/2;
            width: $size;
            height: $size;
            font-size: 16px;
        }

        &--selected {
            @include background-color(main);
            @include color(main-c);

            @include hover {
                @include color(black-1);
            }
        }
    }
}
