@mixin variables() {
    @include colors-to-variables();
    @include sizes-to-variables();
    --main-font: "Montserrat", sans-serif;
}

$color-bg: var(--color-bg);
$color-main: var(--color-main);
$color-main-c: var(--color-main-c);
$color-black: var(--color-black);
$color-white: var(--color-white);

$color-grey-0: rgb(245, 245, 245);
$color-grey-1: rgb(238, 238, 238);
$color-grey-2: rgb(224, 224, 224);
$color-grey-3: rgb(200, 199, 199);
$color-grey-4: rgb(187, 187, 187);
$color-grey-5: rgb(163, 163, 163);
$color-grey-6: rgb(141, 141, 141);
$color-grey-7: rgb(122, 122, 122);
$color-grey-8: rgb(71, 71, 71);

$color-shadow-1: rgba(black, 0.3);
$color-shadow-2: rgba(black, 0.4);
$color-shadow-3: rgba(black, 0.5);
$color-shadow-4: rgba(black, 0.6);

$box-shadow-1: 0 0 2px 0 $color-shadow-1;
$box-shadow-2: 0 0 4px 0 $color-shadow-1;
$box-shadow-3: 0 0 6px 0 $color-shadow-1;
$box-shadow-4: 0 0 8px 0 $color-shadow-4;
$box-shadow-5: 0 0 10px 0 $color-shadow-4;
$box-shadow-6: 0 0 12px 0 $color-shadow-4;

$colors: (
    bg: white,
    bg-navigator: rgba(238, 238, 238, 0.7),
    bg-navigator-active: rgba(255, 255, 255, 0.9),
    bg-navigator-mobile: rgb(238, 238, 238),
    bg-navigator-active-mobile: rgb(255, 255, 255),
    bg-overlay: rgba(255, 255, 255, 0.9),
    gallery-icon-bg: rgba(0, 0, 0, 0.75),
    copyright: rgba(255, 255, 255, 0.66),
    main: #0de6a8,
    main-1: rgba(13, 230, 168, 0.1),
    main-c: white,
    black-1: #474747,
    white-1: #fff,
    bg-button: #fff,
    error: rgb(194, 1, 1),
    grey-0: rgb(245, 245, 245),
    grey-1: rgb(238, 238, 238),
    grey-1-transparent: rgba(238, 238, 238, 0),
    grey-2: rgb(224, 224, 224),
    grey-3: rgb(200, 199, 199),
    grey-4: rgb(187, 187, 187),
    grey-5: rgb(163, 163, 163),
    grey-6: rgb(141, 141, 141),
    grey-7: rgb(122, 122, 122),
    grey-8: rgb(71, 71, 71),
    shadow-1: rgba(black, 0.3),
    shadow-2: rgba(black, 0.4),
    shadow-3: rgba(black, 0.5),
    shadow-4: rgba(black, 0.6),
    view-frame-fill: rgba(13, 230, 168, 0.01),
    view-frame-fill-hover: rgba(13, 230, 168, 0.8),
    state-available: rgb(13, 230, 168),
    state-available-highlight: rgba(13, 230, 168, 0.2),
    state-available-fill: rgba(13, 230, 168, 0.2),
    state-available-fill-open: rgba(13, 230, 168, 0.7),
    state-available-fill-hover: rgba(13, 230, 168, 0.8),
    state-reserved: rgb(16, 82, 136),
    state-reserved-highlight: rgba(16, 82, 136, 0.2),
    state-reserved-fill: rgba(16, 82, 136, 0.2),
    state-reserved-fill-open: rgba(16, 82, 136, 0.7),
    state-reserved-fill-hover: rgba(16, 82, 136, 0.8),
    state-sold: rgb(255, 0, 0),
    state-sold-highlight: rgba(255, 0, 0, 0.2),
    state-sold-fill: rgba(255, 0, 0, 0.2),
    state-sold-fill-open: rgba(255, 0, 0, 0.7),
    state-sold-fill-hover: rgba(255, 0, 0, 0.8),
    gradient-white-start: rgba(white, 1),
    gradient-white-end: rgba(white, 0),
    gradient-grey-0-start: rgba(245, 245, 245, 1),
    gradient-grey-0-end: rgba(245, 245, 245, 0),
    logo-left-top-bg: rgba(255, 255, 255, 0.9),
);

$sizes: (
    stroke-width: 2px,
);

@mixin colors-to-variables() {
    @each $name, $color in $colors {
        --color-#{$name}: #{$color};
    }
}

@mixin sizes-to-variables() {
    @each $name, $value in $sizes {
        --size-#{$name}: #{$value};
    }
}

@function size-var($size-name) {
    @return map-get($sizes, $size-name);
}

@mixin stroke-width($size-name) {
    stroke-width: size-var($size-name);
    stroke-width: var(--size-#{$size-name});
}

@function color-value($color-name) {
    @return map-get($colors, $color-name);
}

@function color-var($color-name) {
    @return var(--color-#{$color-name});
}

@mixin fill($color-name) {
    fill: color-value($color-name);
    fill: color-var($color-name);
}

@mixin stroke($color-name) {
    stroke: color-value($color-name);
    stroke: color-var($color-name);
}

@mixin background-color($color-name) {
    background-color: color-value($color-name);
    background-color: color-var($color-name);
}

@mixin border-color($color-name) {
    border-color: color-value($color-name);
    border-color: color-var($color-name);
}

@mixin border-side-color($side, $color-name) {
    border-#{$side}-color: color-value($color-name);
    border-#{$side}-color: color-var($color-name);
}

@mixin border-side($side, $width, $color-name, $style: solid) {
    border-#{$side}-width: #{$width}px;
    border-#{$side}-style: #{$style};
    @include border-side-color($side, $color-name);
}

@mixin border($width, $color-name, $style: solid) {
    border-width: #{$width}px;
    border-style: #{$style};
    @include border-color($color-name);
}

@mixin color($color-name) {
    color: color-value($color-name);
    color: color-var($color-name);
}

@mixin box-shadow($size: 1, $shadow-color: 1) {
    box-shadow: 0 0 #{$size * 2}px 0 color-value(shadow-#{$shadow-color});
    box-shadow: 0 0 #{$size * 2}px 0 color-var(shadow-#{$shadow-color});
}

@mixin box-shadow-color($size: 1, $shadow-color: shadow-1) {
    box-shadow: 0 0 #{$size * 2}px 0 color-value($shadow-color);
    box-shadow: 0 0 #{$size * 2}px 0 color-var($shadow-color);
}

@mixin vertical-gradient($name: white) {
    background: linear-gradient(color-value(gradient-#{$name}-end), color-value(gradient-#{$name}-start));
    background: linear-gradient(color-var(gradient-#{$name}-end), color-var(gradient-#{$name}-start));
}
