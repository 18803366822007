@import "../../../../scss/variables";

._3desm {
    &__flat-slice-location-bar {
        flex: 0 0 100%;
        padding: 10px;

        &__content {
            display: flex;
            width: 100%;
            padding: 10px 0;
            align-items: stretch;
            border-width: 1px;
            border-style: solid;
            @include border-color(grey-2);
        }

        &__element {
            flex: 1 0 33.3%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 5px;
            font-size: 12px;

            &:not(:last-child) {
                border-right-width: 1px;
                border-right-style: solid;
                @include border-side-color(right, grey-2);
            }
        }
    }
}
