@import "../../scss/variables";

._3desm {
    &__flat-table {
        width: 100%;
        padding: 0 10px 60px 10px;

        &__table {
            width: 100%;
            border-collapse: collapse;
            text-align: center;
            font-size: 12px;
            border-width: 0;
            border-spacing: 0;
        }

        &__thead {
            border: none;
            border-width: 0;
            border-spacing: 0;

            &-tr {
                border-width: 0;
                border: none;
                border-spacing: 0;
            }
        }

        &__th {
            padding: 10px 1px;
            @include color(grey-7);
            position: sticky;
            top: 0;
            @include background-color(grey-0);
            z-index: 5;
            cursor: pointer;

            &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 1px;
                @include background-color(grey-1);
            }
        }

        &__name {
            font-weight: 700;
            font-size: 13px;

            ._3desm__flat-table__tr--available & {
                @include color(state-available);
            }

            ._3desm__flat-table__tr--reserved & {
                @include color(state-reserved);
            }

            ._3desm__flat-table__tr--sold & {
                @include color(state-sold);
            }
        }

        &__tr {
            cursor: pointer;

            &:nth-of-type(2n) {
                @include background-color(grey-1);
            }

            @include desktop() {
                &--available {
                    &._3desm__flat-table__tr--hover {
                        @include background-color(state-available-highlight);
                    }
                }

                &--reserved {
                    &._3desm__flat-table__tr--hover {
                        @include background-color(state-reserved-highlight);
                    }
                }

                &--sold {
                    &._3desm__flat-table__tr--hover {
                        @include background-color(state-sold-highlight);
                    }
                }
            }
        }

        &__td {
            padding: 5px 0;
            z-index: 0;
        }

        &__compare {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }
}
