@import "../../scss/variables";

._3desm {
    &__flat-filters {
        display: flex;
        flex-direction: column;

        &__header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &-title {
                font-size: 20px;
                @include color(black-1);
                padding: 10px;
                font-weight: 500;

                @include breakpoint-sm-dn {
                    font-size: 16px;
                }
            }

            &-short {
                font-size: 12px;
                @include color(black-1);
                padding: 10px;
                font-weight: 500;

                @include breakpoint-sm-dn {
                    font-size: 10px;
                }
            }
        }

        &__open-button {
            height: 35px;
            width: 100%;
            @include background-color(grey-1);
            @include color(main);
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            user-select: none;

            ._3desm__flat-filters--open & {
                @include color(grey-7);
            }

            &-icon {
                @include ff-icon;
                font-size: 20px;
            }

            &-title {
                font-size: 12px;
                text-transform: uppercase;
            }
        }

        &__collapsable {
            max-height: 0px;
            overflow: hidden;
            transition: max-height 0.2s ease-in-out;

            ._3desm__flat-filters--open & {
                max-height: 370px;
            }
        }

        &__footer {
            border-top-width: 1px;
            border-top-style: solid;
            @include border-side-color(top, grey-1);
            padding: 0 5px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: 0 0 51px;
            height: 50px;
        }

        &__availability {
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 10px;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            @include border-side-color(bottom, grey-1);
        }

        &__scrollable {
            max-height: 250px;
            overflow-x: hidden;
            overflow-y: scroll;

            @include mobile {
                max-height: 200px;
            }

            &-shadow {
                position: absolute;
                width: 100%;
                bottom: 0;
                height: 30px;
                @include vertical-gradient(white);
                pointer-events: none;
                opacity: 0;
                transition: opacity 0.2s ease-out;

                &--visible {
                    opacity: 1;
                }
            }
        }

        &__section {
            display: flex;
            flex-wrap: wrap;
            position: relative;

            &-ranges {
                flex: 1 0 300px;
                display: flex;
                flex-wrap: wrap;
                padding: 5px;
            }

            &-props {
                flex: 10 0 150px;
                display: flex;
                flex-wrap: wrap;
                padding: 5px;
                align-items: center;
            }
        }

        &__range {
            padding: 5px;
            flex: 1 0 150px;
        }

        &__prop {
            flex: 1 1 25%;
            min-width: 200px;
            padding: 0 20px 0 0;

            &-placeholder {
                flex: 1 1 25%;
                min-width: 200px;
                height: 0;
            }
        }

        &__clear {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: 12px;
            padding: 0 10px;
            border-width: 1px;
            border-style: solid;
            @include border-color(grey-3);
            border-radius: 20px;
            @include color(grey-7);
            cursor: pointer;
            height: 30px;

            &-icon {
                @include ff-icon;
                font-size: 18px;
                margin-right: 5px;
            }

            @include hover {
                @include color(main);
                @include border-color(main);
            }
        }
    }
}
