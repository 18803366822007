@import "../../scss/variables";

._3desm {
    &__location-search {
        padding: 10px 10px 10px 40px;
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;

        &-graphic {
            position: absolute;
            width: 20px;
            height: calc(50% + 110px);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            left: -30px;
            top: calc(50% - 10px);

            &-icon {
                &-start {
                    @include ff-icon;
                    font-size: 18px;
                    @include color(main);
                }

                &-end {
                    @include ff-icon;
                    font-size: 24px;
                    @include color(main);
                }
            }

            &-dot {
                width: 6px;
                height: 6px;
                border-radius: 3px;
                @include background-color(main);
            }
        }

        &-origin {
            @include border(2, main);
            padding: 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            // height: 80px;
            justify-content: center;
            border-radius: 5px;
            position: relative;

            &-title {
                font-size: 20px;
                font-weight: 500;
            }

            &-address {
                font-size: 14px;
                padding-top: 5px;
            }
        }

        &-target {
            display: flex;
            flex-direction: column;
            @include border(2, main);
            padding: 10px;
            margin-top: 20px;
            border-radius: 5px;

            &-combo {
                display: flex;
            }

            &-input {
                flex: 1 0 100px;
                height: 40px;
                @include background-color(white-1);
                @include border(1, main);
                padding: 0 10px;
                @include ff-main;
                font-size: 16px;
                @include color(black-1);
                border-radius: 3px 0 0 3px;

                &:focus,
                &:active {
                    outline: none;
                }
            }

            &-submit {
                width: 40px;
                flex: 0 0 40px;
                @include ff-icon;
                height: 40px;
                @include background-color(main);
                @include border(1, main);
                font-size: 24px;
                @include color(white-1);
                border-radius: 0 3px 3px 0;
                cursor: pointer;

                &:focus,
                &:active {
                    outline: none;
                }
            }

            &-divider {
                display: flex;
                align-items: center;
                margin: 10px 0;

                &-line {
                    height: 1px;
                    @include background-color(main);
                    flex: 1 0 1px;
                }

                &-label {
                    font-size: 12px;
                    text-transform: uppercase;
                    @include color(main);
                    padding: 0 10px;
                }
            }

            &-select-point {
                display: flex;
                align-items: center;
                cursor: pointer;

                &-icon {
                    @include border(1, main);
                    @include color(main);
                    @include background-color(white-1);
                    @include ff-icon;
                    border-radius: 3px;
                    flex: 0 0 40px;
                    height: 40px;
                    margin-right: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all 0.2s ease-out;
                    font-size: 24px;

                    &--active {
                        @include background-color(main);
                        @include color(white-1);
                    }
                }

                &-label {
                    font-size: 12px;
                    text-transform: uppercase;
                }
            }
        }

        &-result {
            margin-top: 20px;
            @include background-color(main);
            padding: 10px;
            text-align: center;
            @include color(main-c);
            font-size: 16px;
            border-radius: 5px;
        }
    }
}
