@import "../../scss/variables";

._3desm {
    &__flat-list {
        display: flex;
        flex-direction: column;
        height: 100%;
        @include background-color(grey-0);

        &__nav-button {
            @include mobile {
                display: none;
            }

            position: absolute;
            right: 0px;
            top: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &__title {
            width: 100%;
            padding: 16px 10px 0;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            font-size: 30px;
            flex: 0 0 auto;
        }

        &__subtitle {
            width: 100%;
            flex: 0 0 auto;
            font-size: 14px;
            padding: 0 10px;
            @include color(grey-7);
        }

        &__filters {
            @include background-color(bg);
            @include box-shadow(1);
            flex: 0 0 auto;
            margin: 10px;
        }

        &__apartments {
            flex: 1 0 200px;
            width: 100%;
            position: relative;
            // border-top: 1px solid var(--color-grey-1);

            &::before {
                content: "";
                position: absolute;
                top: -1px;
                width: 100%;
                left: 0;
                height: 2px;
                @include background-color(grey-0);
                z-index: 10;
            }

            @include mobile {
                flex: 0 0 calc(100vh - 210px);
            }

            &-scrollable {
                height: 100%;
                width: 100%;
                position: absolute;
                overflow-y: scroll;
                overflow-x: hidden;

                // @include mobile {
                //     position: relative;
                // }

                &-shadow {
                    position: absolute;
                    width: 100%;
                    bottom: 0;
                    height: 50px;
                    @include vertical-gradient(grey-0);
                    pointer-events: none;
                    opacity: 0;
                    transition: opacity 0.2s ease-out;

                    @include mobile {
                        position: fixed;
                        opacity: 1;
                    }

                    &--visible {
                        opacity: 1;
                    }
                }
            }
        }

        &__compare-button {
            $compare-button-height: 60px;

            display: flex;
            justify-content: center;
            width: 100%;
            height: $compare-button-height;
            position: absolute;
            bottom: 0;

            @include mobile {
                // position: sticky;
                // top: 150px;
            }

            &-space {
                flex: 0 0 100%;
                height: $compare-button-height;
            }
        }

        &__no-flats {
            display: flex;
            flex-direction: column;
            padding: 20px 10px 80px;
            text-align: center;
            font-size: 16px;

            &-title {
                font-weight: 700;
            }
        }
    }
}
