@import "../../scss/variables";

._3desm {
    &__flat-card {
        flex: 1 0 320px;
        max-width: 640px;
        padding: 5px;
        cursor: pointer;

        &__content {
            width: 100%;
            @include background-color(bg);
            @include box-shadow(2);
            border-radius: 3px;
            display: flex;
            align-items: stretch;
            overflow: hidden;
            position: relative;

            ._3desm__flat-card--hover & {
                @include box-shadow-color(2, main);
            }
        }

        &__left {
            flex: 1 0 180px;
            padding: 10px;
        }

        &__right {
            flex: 10 0 120px;
            background-image: url("../../assets/blur-2.png");
            background-size: cover;
            position: relative;
        }

        &__layout {
            position: absolute;
            width: 100%;
            height: 100%;
            padding: 10px;

            &-img {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        &__name {
            font-size: 24px;
            font-weight: 500;
            padding-bottom: 10px;
        }

        &__availability {
            font-weight: 500;
            font-size: 16px;
            @include color(state-available);
            padding-bottom: 10px;

            &-reserved {
                @include color(state-reserved);
            }

            &-sold {
                @include color(state-sold);
            }
        }

        &__prop {
            display: flex;
            flex-direction: column;
            padding: 5px 0;

            &-label {
                font-size: 12px;
                text-transform: uppercase;
            }

            &-value {
                font-size: 16px;
                font-weight: 500;
            }
        }

        &__compare-button {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
}
