@import "../../scss/variables";

._3desm {
    &__flat-details {
        width: 100%;
        height: 100%;
        flex-direction: column;
        display: flex;
        @include background-color(grey-0);

        &__title {
            padding: 10px;
            display: flex;
            align-items: center;
            font-size: 30px;
            text-transform: uppercase;
            white-space: normal;
            width: 100%;
            flex: 0 0 auto;
            flex-wrap: wrap;
        }

        &__close {
            // height: 40px;
            padding: 10px 10px 10px 1px;
            display: flex;
            align-items: center;
            @include color(main);
            cursor: pointer;
            border-bottom-width: 1px;
            border-bottom-style: solid;
            @include border-side-color(bottom, grey-2);
            flex: 0 0 auto;

            &-icon {
                @include ff-icon;
                font-size: 30px;
                text-align: left;
            }

            &-title {
                font-size: 15px;
                text-transform: uppercase;
            }
        }

        &__info {
            flex: 1 0 100px;
            overflow-x: hidden;
            overflow-y: auto;
            position: relative;

            @include mobile {
                flex: 0 0 auto;
            }
        }

        &__wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
            // display: none;

            @include mobile {
                position: relative;
            }
        }

        &__disclaimer {
            font-size: 12px;
            text-align: center;
            padding: 50px 10px 10px;
            flex: 1 0 auto;
        }
    }
}
