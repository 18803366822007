@import "../../scss/variables";

._3desm {
    &__powered-by {
        position: absolute;
        bottom: 10px;
        left: 10px;
        @include color(copyright);
        font-family: "Rubik", sans-serif;
        font-weight: 300;
        letter-spacing: 0.07em;
        font-size: 18px;
        text-align: center;
        user-select: none;
        pointer-events: none;
        
        @include mobile {
            font-size: 10px;
        }
    }
}