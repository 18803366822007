@import "../../../../scss/variables";

._3desm {
    &__flat-slice-layout {
        flex: 0 0 100%;
        height: 300px;
        padding: 10px;

        &__backdrop {
            width: 100%;
            height: 100%;
            padding: 10px;
            background-image: url("../../../../assets/blur-2.png");
            background-size: cover;
        }

        &__img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
