@import "../../scss/variables";

._3desm {
    &__gallery-block {
        display: flex;
        flex-wrap: wrap;
        flex: 1 1 500px;
        position: relative;

        &-ratio {
            width: 100%;
            padding-top: 100%;
        }

        &-items {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            ._3desm__gallery-block--count-2._3desm__gallery-block--rotate-2 & {
                flex-direction: column;
            }

            ._3desm__gallery-block--count-3._3desm__gallery-block--rotate-2 & {
                flex-direction: column;
            }

            ._3desm__gallery-block--count-3._3desm__gallery-block--rotate-4 & {
                flex-direction: column;
            }
        }

        &-item {
            width: 100%;
            height: 100%;
            position: relative;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            ._3desm__gallery-block--count-2 & {
                flex: 0 0 50%;
            }

            ._3desm__gallery-block--count-3 & {
                flex: 0 0 50%;
                height: 50%;

                &:nth-child(1) {
                    flex: 0 0 100%;
                }
            }

            ._3desm__gallery-block--count-3._3desm__gallery-block--rotate-2 & {
                width: 50%;
            }

            ._3desm__gallery-block--count-3._3desm__gallery-block--rotate-3 & {
                &:nth-child(1) {
                    order: 3;
                }
            }

            ._3desm__gallery-block--count-3._3desm__gallery-block--rotate-4 & {
                width: 50%;

                &:nth-child(1) {
                    order: 3;
                }
            }

            ._3desm__gallery-block--count-4 & {
                flex: 0 0 50%;
                height: 50%;
            }

            &-wrapper {
                position: relative;
                width: 100%;
                height: 100%;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &-img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.25s ease-out;
                cursor: pointer;

                &:nth-child(1) {
                    z-index: 1;
                }
                &:nth-child(2) {
                    z-index: 2;
                }

                &:hover {
                    transform: scale(1.05);
                }
            }

            &-play {
                position: absolute;
                width: 100px;
                height: 100px;
                border-radius: 50px;
                z-index: 4;
                @include background-color(gallery-icon-bg);
                pointer-events: none;
                user-select: none;

                &-icon {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    @include ff-icon;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 60px;
                    @include color(white-1)
                }
            }
        }
    }
}
