@import "../../scss/variables";

._3desm {
    &__view-mode-switch {
        padding: 0 10px 10px;
        @include border-side(bottom, 1, grey-1);
        margin-bottom: 1px;

        &__content {
            display: flex;
            justify-content: space-between;
            @include background-color(white-1);
            @include box-shadow(1);
            align-items: center;
        }

        &__title {
            font-size: 20px;
            @include color(black-1);
            padding: 0 10px;
            font-weight: 500;

            @include breakpoint-sm-dn {
                font-size: 16px;
            }
        }

        &__switch {
            padding: 0 10px;
        }
    }
}
