@import "../../../scss/variables";

@keyframes animation_3desm_finished_loading {
    0% {
        @include background-color(bg);
        @include color(black-1);
    }
    10% {
        @include background-color(main);
        @include color(main-c);
    }
    90% {
        @include background-color(main);
        @include color(main-c);
    }
    100% {
        @include background-color(bg);
        @include color(black-1);
    }
}

._3desm {
    &__viewer-key-frames {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        user-select: none;
        top: 0;
        @include background-color(bg);

        &-images {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            user-select: none;
        }

        &-buttons {
            position: absolute;
            width: 100%;
            left: 0;
            bottom: 10px;
            user-select: none;
            display: flex;
            justify-content: center;

            @include mobile {
                bottom: 5px;
            }
        }

        &-img {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            user-select: none;
        }

        &-info {
            @include background-color(bg);
            display: flex;
            margin: 0 10px;
            min-height: 40px;
            border-radius: 50px;

            @include desktop {
                min-height: 60px;
            }

            &--loaded {
                animation-name: animation_3desm_finished_loading;
                animation-duration: 5s;
                animation-iteration-count: 1;
                animation-delay: 0.1s;
            }

            &__rotate-left,
            &__rotate-right {
                @include ff-icon;
                font-size: 40px;
                flex: 0 0 40px;
                height: 100%;
                cursor: pointer;
                display: flex;
                align-items: center;
            }

            &__state {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 0 10px;

                &--clickable {
                    cursor: pointer;
                }

                &--only {
                    padding: 0 20px;
                }

                @include mobile {
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }

                &-title {
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                }

                &-subtitle {
                    font-size: 12px;
                    text-transform: uppercase;
                    font-weight: bold;
                }

                &-progress {
                    text-align: center;
                    margin-left: 20px;
                    font-weight: bold;
                    width: 45px;
                }

                &-icon {
                    @include ff-icon;
                    margin-left: 5px;
                    font-size: 30px;

                    @include mobile {
                        display: none;
                    }
                }
            }
        }
    }
}

@for $i from 0 through 300 {
    ._3desm__viewer-key-frames--index-#{$i} ._3desm__viewer-key-frames-img--index-#{$i} {
        opacity: 1;
    }
}
