._3desm {
    &__viewer-images {
        width: 100%;
        height: 100%;
        position: relative;

        &__image {
            width: 100%;
            height: 100%;
            position: absolute;
            opacity: 0;
            user-select: none;
        }
    }
}

@for $i from 0 through 300 {
    ._3desm__viewer-images-index-#{$i} ._3desm__viewer-images__image-index-#{$i} {
        opacity: 1;
    }
}
