@mixin mobile() {
    ._3desm__mobile & {
        @content;
    }
}

@mixin desktop() {
    ._3desm__desktop & {
        @content;
    }
}

@mixin breakpoint-sm-dn() {
    @media (max-width: 439px) {
        @content;
    }
}

@mixin breakpoint-md-dn() {
    @media (max-width: 599px) {
        @content;
    }
}

@mixin hover-generic() {
    @media (hover: hover) {
        @content;
    }
}

@mixin hover() {
    @include hover-generic() {
        &:hover {
            @content;
        }
    }
}

@mixin autocompleteBackground($color-name) {
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px color-value($color-name) inset;
        -webkit-box-shadow: 0 0 0px 1000px color-var($color-name) inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}
