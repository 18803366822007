@import "../../scss/variables";

._3desm {
    &__location-marker-type {
        display: flex;
        align-items: center;
        width: 100%;
        cursor: pointer;

        &-icon {
            flex: 0 0 60px;
            padding: 5px 10px;

            &-img {
                width: 100%;
            }
        }

        &-content {
            flex: 1 0 100px;
            display: flex;
            flex-direction: column;
        }

        &-switch {
            flex: 0 0 55px;
            padding: 5px;
        }
    }
}
