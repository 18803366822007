@import "../../../scss/variables";

._3desm {
    &__field-switch {
        display: flex;
        align-items: center;
        cursor: pointer;
        width: 100%;
        min-width: 130px;
        padding: 5px;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

        &-short {
            width: 35px;
            min-width: 35px;
        }

        &__check {
            height: 20px;
            width: 35px;
            flex: 0 0 35px;
            display: flex;
            position: relative;
            margin-right: 5px;
            @include background-color(grey-1);
            border-radius: 10px;
            transition: all 0.2s ease-in-out;

            ._3desm__field-switch-on & {
                @include background-color(main);
            }

            &::before {
                content: "";
                position: absolute;
                width: 20px;
                height: 20px;
                border-width: 2px;
                border-style: solid;
                @include border-color(grey-7);
                top: 0;
                left: 0;
                box-sizing: border-box;
                border-radius: 10px;
                @include background-color(white-1);
                transition: all 0.2s ease-in-out;

                ._3desm__field-switch-on & {
                    @include border-color(main);
                    left: 15px;
                }
            }
        }

        &__label {
            font-size: 12px;
            @include color(black-1);
        }
    }
}
