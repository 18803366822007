@import "../../../scss/variables";

@keyframes _3desm__open-animation-available {
    0% {
        @include fill(state-available-fill);
    }
    50% {
        @include fill(state-available-fill-open);
    }
    100% {
        @include fill(state-available-fill);
    }
}
@keyframes _3desm__open-animation-reserved {
    0% {
        @include fill(state-reserved-fill);
    }
    50% {
        @include fill(state-reserved-fill-open);
    }
    100% {
        @include fill(state-reserved-fill);
    }
}

@keyframes _3desm__open-animation-sold {
    0% {
        @include fill(state-sold-fill);
    }
    50% {
        @include fill(state-sold-fill-open);
    }
    100% {
        @include fill(state-sold-fill);
    }
}

._3desm {
    &__frame-elements {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &__frame-element {
        @include fill(state-available-fill);
        @include stroke(state-available);
        @include stroke-width(stroke-width);

        &._3desm__frame-element--view {
            @include fill(view-frame-fill);

            ._3desm__frame-elements--no-interaction & {
                @include hover {
                    @include fill(view-frame-fill-hover);
                }
            }
        }

        ._3desm__frame-elements--no-interaction & {
            cursor: pointer;

            &._3desm__frame-element--hover {
                @include desktop {
                    @include fill(state-available-fill-hover);
                }
            }

            @include hover {
                @include fill(state-available-fill-hover);
            }
        }

        &._3desm__frame-element--open {
            animation: _3desm__open-animation-available 1s linear infinite;
        }

        &__reserved {
            @include fill(state-reserved-fill);
            @include stroke(state-reserved);

            ._3desm__frame-elements--no-interaction & {
                &._3desm__frame-element--hover {
                    @include desktop {
                        @include fill(state-reserved-fill-hover);
                    }
                }

                @include hover {
                    @include fill(state-reserved-fill-hover);
                }
            }

            &._3desm__frame-element--open {
                animation: _3desm__open-animation-reserved 1s linear infinite;
            }
        }

        &__sold {
            @include fill(state-sold-fill);
            @include stroke(state-sold);

            ._3desm__frame-elements--no-interaction & {
                &._3desm__frame-element--hover {
                    @include desktop {
                        @include fill(state-sold-fill-hover);
                    }
                }

                @include hover {
                    @include fill(state-sold-fill-hover);
                }
            }

            &._3desm__frame-element--open {
                animation: _3desm__open-animation-sold 1s linear infinite;
            }
        }
    }
}
