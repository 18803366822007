._3desm {
    &__app {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;

        @include variables();
        @include ff-main();

        @include color(black-1);
        @include background-color(bg);

        * {
            box-sizing: border-box;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
    }

    &__root {
        width: 100%;
        height: 100%;
        position: relative;
        overflow: hidden;

        &__mobile {
            position: fixed;
            z-index: 500;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            overflow: auto;
            @include background-color(bg);
            padding-top: 110px;
        }
    }
}

._3desm__ff-icon {
    @include ff-icon;
}

._3desm__ff-icon-1 {
    @include ff-icon-1;
}
