@import "../../scss/variables";

._3desm {
    &__doll-house {
        position: relative;
        width: 100%;
        height: 100%;

        &__viewer {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
}
