@import "../../scss/variables";

._3desm {
    &__gallery {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 21;
        @include background-color(bg);

        &-nav-button {
            position: absolute;
            right: 0;
            top: 0;
            z-index: 10;
        }

        &-scroll {
            position: relative;
            width: 100%;
            height: 100%;
            overflow-x: hidden;
            overflow-y: auto;
        }

        &-content {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            padding: 5px;

            &-item-placeholder {
                flex: 1 0 500px;
                height: 0;
            }
        }

        &-slideshow {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 22;
            @include background-color(bg);

            &-close {
                position: absolute;
                z-index: 30;
                right: 0;
                top: 0;
            }

            &-arrow-left,
            &-arrow-right {
                position: absolute;
                width: 10%;
                z-index: 25;
                display: flex;
                align-items: center;
                height: 100%;
                @include ff-icon;
                padding: 10px;
                font-size: 60px;
                cursor: pointer;
            }

            &-arrow-left {
                left: 10px;
                justify-content: flex-start;
            }

            &-arrow-right {
                right: 10px;
                justify-content: flex-end;
            }

            &-arrow-icon {
                width: 80px;
                height: 80px;
                @include background-color(bg-overlay);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 40px;
            }

            &-current-item {
                position: absolute;
                z-index: 20;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 10px;

                &-photo {
                    position: relative;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }

                &-img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;

                    &:nth-child(1) {
                        z-index: 1;
                    }
                    &:nth-child(2) {
                        z-index: 2;
                    }
                }
            }
        }
    }
}
