@import "../../scss/variables";

._3desm {
    &__cards {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 5px;

        &__card-placeholder {
            flex: 1 0 320px;
            max-width: 640px;
            height: 0;
        }

        &__compare-button-space {
            flex: 0 0 100%;
            height: 60px;
        }
    }
}
