@import "../../scss/variables";

._3desm {
    &__viewer {
        width: 100%;
        height: 100%;
        position: relative;

        @include mobile {
            height: unset;
            // padding-top: (3/4) * 100%;
        }

        &-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            z-index: 1;

            @include mobile {
                position: relative;
                flex-direction: column;
            }
        }

        &-center {
            position: relative;
            height: 100%;
            width: auto;
            display: flex;
            justify-content: center;

            &--interaction {
                touch-action: pan-y;
            }

            @include mobile() {
                width: 100%;
                height: unset;
                padding-top: 100% * (3/4);
            }
        }

        &-ratio {
            height: 100%;
            position: relative;
            width: auto;
        }

        &-images {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transition: opacity 0.1s ease-out;

            &-transition {
                opacity: 0;
            }
        }

        &-loader {
            position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        }

        &-frame-elements {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            touch-action: pan-y;
            transition: opacity 0.1s ease-out;
            cursor: ew-resize;

            &--visible {
                opacity: 1;
            }

            &--transition {
                opacity: 0;
            }
        }

        &__buttons-top {
            height: 60px;
            position: absolute;
            top: 10px;
            width: 100%;
            padding-left: 130px;
            padding-right: 200px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            @include mobile {
                position: relative;
                padding: none;
                overflow-y: hidden;
                overflow-x: auto;
                flex-wrap: nowrap;
                padding: 0;
                top: 0;
                display: block;
                @include border-side(bottom, 1, grey-1);
                order: 3;
            }

            &-scroll {
                box-sizing: border-box;
                border-right: 10px solid black;
            }

            &-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                min-width: 100%;

                @include mobile {
                    flex-wrap: nowrap;
                    justify-content: flex-start;

                    &::after {
                        content: "";
                        flex: 0 0 1px;
                    }
                }

                ._3desm__button {
                    @include mobile {
                        flex: 0 0 auto;
                    }
                }
            }
        }

        &__buttons-right {
            position: absolute;
            top: 10px;
            right: 20px;

            @include mobile {
                position: relative;
                display: flex;
                width: 100%;
                top: unset;
                right: unset;
                flex-direction: row;
                justify-content: flex-start;
                order: 2;
            }
        }

        &__orientation {
            $size: 40px;
            position: absolute;
            right: 140px;
            top: 20px;

            @include mobile {
                right: 10px;
                top: 10px;
            }

            &-label {
                width: $size;
                height: $size;
                position: relative;
                z-index: 1;

                &::before {
                    content: "";
                    width: 10px;
                    height: 10px;
                    @include background-color(main);
                    position: absolute;
                    top: -4px;
                    left: ($size / 2) - 5px;
                    transform: rotate(45deg);
                }

                &::after {
                    content: "N";
                    width: $size;
                    height: $size;
                    @include background-color(white-1);
                    border-radius: $size / 2;
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                    @include color(grey-7);
                    @include box-shadow(1) // font-weight: 500;
;
                }
            }
        }

        &__flat-navigator {
            position: absolute;
            right: -100px;
            top: 100px;
            transition: right 0.2s ease-out;

            z-index: 4;

            @include mobile {
                display: none;
                right: 0;
            }

            &--open {
                right: 0;

                @include mobile {
                    display: block;
                    top: unset;
                    bottom: 0;
                    width: 100%;
                    height: 60px;
                }
            }
        }

        &__flat-media {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            @include background-color(bg);
            z-index: 2;

            @include mobile {
                bottom: 60px;
            }
        }
    }
}
