@import "../../../../scss/variables";

._3desm {
    &__flat-slice-rooms {
        display: flex;
        flex-direction: column;
        flex: 1 0 300px;
        max-width: 400px;
        padding: 10px;

        &__list {
            display: flex;
            flex-direction: column;
        }

        &__item {
            display: flex;
            align-items: center;
            padding: 5px 10px;
            justify-content: space-between;
            font-size: 14px;

            &-label {
                padding-right: 10px;
            }

            &-value {
                font-weight: 700;
                
                sup {
                    font-size: 10px;
                }
            }
        }
    }
}
