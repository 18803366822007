@import "../../../scss/variables";

._3desm {
    &__field-wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        &--short {
            align-items: flex-start;
        }

        &__label {
            font-size: 12px;
            text-transform: uppercase;
            @include color(grey-7);
        }
    }
}
