@import "../../scss/variables";

._3desm {
    &__mobile-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: fixed;
        top: 0;
        width: 100%;
        left: 0;
        z-index: 20;
        height: 110px;
        @include box-shadow(4);
        @include background-color(bg);

        &__logo {
            width: 160px;
            padding: 0 10px;
        }

        &__close {
            @include ff-icon;
            height: 100%;
            width: 70px;
            padding: 10px;
            text-align: right;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-size: 40px;
            @include color(main);
        }
    }
}
