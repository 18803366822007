@import "../../scss/variables";

._3desm {
    &__doll-house-viewer {
        position: relative;
        width: 100%;
        height: 100%;
        user-select: none;
        touch-action: pan-y;

        &--interaction {
            touch-action: none;
        }

        &__image {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
            user-select: none;

            object-fit: cover;
            object-position: center center;
        }
    }
}
@for $i from 0 through 300 {
    ._3desm__doll-house-viewer--index-#{$i} ._3desm__doll-house-viewer__image-index-#{$i} {
        opacity: 1;
    }
}
