@mixin ff-main() {
    font-family: "Rubik", sans-serif;
    font-family: var(--main-font);
}

@mixin ff-icon-base() {
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    user-select: none;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: "liga";
}

@mixin ff-icon() {
    @include ff-icon-base();
    font-family: "Material Icons";
}

@mixin ff-icon-1() {
    @include ff-icon-base();
    font-family: "icon1";
}
