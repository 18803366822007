@import "../../../../scss/variables";

._3desm {
    &__flat-slice-availability {
        flex: 0 0 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0 10px;

        @include breakpoint-sm-dn {
            flex-direction: column;
            align-items: flex-start;
        }

        &__label {
            font-size: 24px;
            font-weight: 500;
            padding: 10px;
            text-transform: uppercase;

            &--available {
                @include color(main);
            }
        }

        &__data {
            @include mobile {
                padding-bottom: 20px;
            }

            &-availability {
                font-size: 24px;
                font-weight: 500;
                padding: 10px 0 0;
                text-transform: uppercase;

                &--available {
                    @include color(main);
                }

                &--reserved {
                    @include color(state-reserved);
                }

                &--sold {
                    @include color(state-sold);
                }
            }
        }

        &__element {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 10px 0;

            &--right {
                align-items: flex-end;
                text-align: right;
            }

            &-label {
                font-size: 14px;
                @include color(grey-7);
            }

            &-value {
                font-size: 24px;
                font-weight: 500;
                padding-top: 0px;

                sup {
                    font-size: 12px;
                }
            }

            &-subvalue {
                font-size: 14px;
                font-weight: 400;
                line-height: 1em;

                sup {
                    font-size: 12px;
                }
            }
        }

        &__buttons {
            display: flex;
            align-items: center;
            flex-direction: column;
            align-items: flex-end;
            flex: 1 1 auto;
            padding: 10px 0;

            @include mobile {
                align-items: center;
                width: 100%;
                padding-right: 10px;
            }

            ._3desm__button {
                margin: 5px 10px;
                text-align: right;
            }
        }

        &__button-compare {
            height: 40px;
            background-color: white;
            @include color(black-1);
            padding: 0 0 0 20px;
            border-radius: 20px;
            font-size: 16px;
            margin: 5px 10px;
            text-transform: uppercase;
            @include ff-main;
            outline: none;
            cursor: pointer;
            border: none;
            @include box-shadow(1);
            transition: all 0.2s ease-out;
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 0 1 auto;

            &-text {
                flex: 0 1 auto;
                display: flex;
                text-align: right
            }

            &-icon {
                @include ff-icon;
                flex: 0 0 50px;
                width: 50px;
                height: 100%;
                padding-right: 10px;
                text-align: center;
                display: flex;
                justify-content: center;
                align-items: center;
                @include border-side(left, 1, grey-1-transparent);
                transition: all 0.2s ease-in-out;
                border-radius: 0 16px 16px 0;

                &--active {
                    margin-left: 10px;
                    @include border-side-color(left, grey-1);
                    @include background-color(main);
                    @include color(main-c);
                }
            }
        }

        &__compare {
            &-label {
                text-align: right;
                padding-right: 10px;
                font-size: 12px;
                text-transform: uppercase;
                @include color(main);
                text-decoration: none;
                cursor: pointer;
                transform: translate(8px, 0);
                opacity: 0;
                transition: all 0.2s ease-out;
                pointer-events: none;

                &--active {
                    opacity: 1;
                    transform: translate(0, 0);
                    pointer-events: unset;
                }
            }
        }
    }
}
