@import "../../../scss/variables";

._3desm {
    $selector-width: 20px;
    $selector-height: 40px;
    $track-height: 2px;
    $selector-padding: 5px;

    $track-top: $selector-padding + ($selector-width - $track-height) / 2;

    &__field-range {
        display: flex;
        width: 100%;
        height: $selector-height + 2 * $selector-padding;
        position: relative;
        touch-action: pan-y;
        user-select: none;

        &__track {
            position: absolute;
            width: 100%;
            height: $track-height;
            top: $track-top;
            @include background-color(grey-3);
        }

        &__track-fill {
            position: absolute;
            top: $track-top;
            height: $track-height;
            @include background-color(grey-7);

            ._3desm__field-range-changed & {
                @include background-color(main);
            }
        }

        &__selector {
            width: $selector-width;
            height: $selector-height;
            top: $selector-padding;
            position: absolute;
            cursor: pointer;

            &::before {
                content: "";
                position: absolute;
                top: -$selector-padding;
                bottom: -$selector-padding;
                left: 0;
                right: 0;
            }

            &-left {
                &::before {
                    left: -$selector-padding;
                }
            }

            &-right {
                &::before {
                    right: -$selector-padding;
                }
            }

            &-content {
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                height: 100%;
            }

            &-circle {
                width: $selector-width;
                height: $selector-width;
                border-radius: $selector-width / 2;
                position: relative;
                @include background-color(bg);
                border-width: 2px;
                border-style: solid;
                @include border-color(grey-7);

                ._3desm__field-range-changed & {
                    @include border-color(main);
                }
            }

            &-label {
                font-size: 12px;
                margin-top: 3px;
                text-align: center;
                @include color(grey-7);

                ._3desm__field-range-changed & {
                    @include color(main);
                }
            }
        }
    }
}
