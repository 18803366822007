@import "../../scss/variables";

._3desm {
    &__viewer-switch {
        width: 80px;
        height: 40px;
        display: flex;
        cursor: pointer;
        position: relative;
        margin: 10px;

        &::before {
            content: "";
            position: absolute;
            width: 40px;
            height: 40px;
            left: 0;
            @include background-color(main);
            // box-shadow: $box-shadow-1;
            @include box-shadow(1);
            z-index: 1;
            border-radius: 20px;
            transition: all 0.2s ease-in-out;
        }

        &__off {
            &::before {
                left: 40px;
            }
        }

        &::after {
            content: "";
            width: 80px;
            height: 32px;
            position: absolute;
            @include background-color(bg);
            top: 4px;
            border-radius: 16px;
            @include box-shadow(1);

        }

        &__icon {
            @include ff-icon;
            width: 40px;
            height: 40px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            transition: all 0.2s ease-in-out;
            font-size: 24px;
            @include color(grey-7);
        }

        &__left {
            @include color(main-c);

            ._3desm__viewer-switch__off & {
                @include color(grey-7);
            }
        }

        &__right {
            ._3desm__viewer-switch__off & {
                @include color(main-c);
            }
        }

        // off {
        //     &::before {
        //         left: 4rem;
        //     }

        //     .viewer-switch__icon {
        //         &.left {
        //             color: $color-grey-7;
        //         }

        //         &.right {
        //             color: $color-white;
        //         }
        //     }
        // }
    }
}
