@import "../../scss/variables";

._3desm {
    &__compare {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        @include mobile {
            user-select: none;
        }

        &__header {
            display: flex;
            justify-content: flex-start;
            align-items: stretch;
            height: 80px;
            flex: 0 0 80px;
            @include box-shadow(6);
            z-index: 2;

            @include mobile {
                height: 110px;
                flex: 0 0 110px;
                // flex-direction: row;
            }

            @include breakpoint-sm-dn {
                flex-wrap: wrap;
            }

            &-logo {
                padding: 0 10px;
                display: flex;
                justify-content: flex-start;
                align-items: center;

                &-img {
                    height: 80px;

                    @include breakpoint-md-dn {
                        height: 50px;
                    }
                }
            }

            &-title {
                display: flex;
                align-items: center;
                padding: 10px 20px;
                font-size: 30px;
                text-transform: uppercase;
                flex: 1 0 100px;

                @include mobile {
                    font-size: 26px;
                    padding: 10px;
                    height: unset;

                    @include breakpoint-md-dn {
                        font-size: 20px;
                    }

                    @include breakpoint-sm-dn {
                        font-size: 18px;
                        padding: 0 10px;
                    }
                }
            }

            &-buttons {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @include breakpoint-sm-dn {
                    flex: 0 0 100%;
                    order: 6;
                    @include border-side(top, 1, grey-0);
                }

                ._3desm__button {
                    @include breakpoint-sm-dn {
                        height: 30px;
                    }
                }
            }

            &-close {
                display: flex;
                align-items: center;
            }
        }

        &__table {
            z-index: 1;
            flex: 1 0 100px;
            width: 100%;
            overflow-x: auto;
            overflow-y: auto;
            background-image: url("../../assets/blur-2.png");
            background-size: cover;
            display: flex;
            align-items: flex-start;
        }

        &__add-more {
            position: sticky;
            top: 40%;
            flex: 1 0 400px;
            display: flex;
            justify-content: center;

            @include mobile {
                flex: 1 0 100vw;
            }

            &-content {
                max-width: 500px;
                padding: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            &-title {
                text-align: center;
                font-weight: 700;
                font-size: 24px;
                padding-bottom: 20px;
            }

            &-icon {
                @include ff-icon;
                font-size: 20px;
                @include background-color(bg);
                width: 30px;
                height: 30px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 15px;
                @include border(1, main);
            }
        }
    }
}
