@import "../../scss/variables";

._3desm {
    &__logo-left-top {
        width: 120px;
        position: absolute;
        height: 60px;
        @include background-color(logo-left-top-bg);
        left: 0;
        top: 0;
        border-radius: 0 0 5px 0;
        @include box-shadow(1);
        padding: 10px;
        display: flex;
        align-items: center;

        @include mobile {
            display: none;
        }

        &-img {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
        }
    }
}
