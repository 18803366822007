._3desm {
    &__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: rgba(white, 0.9);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__loader {
            padding: 5px;
        }

        &__title {
            padding: 5px;
            font-size: 24px;
            text-transform: uppercase;
            font-weight: 700;
        }

        &__subtitle {
            padding: 10px;
            font-size: 18px;
            text-align: center;
        }

        &__progress {
            padding: 5px;
            font-size: 24px;
        }
    }
}
