@import "../../scss/variables";

@keyframes animation-3desm-nav-link {
    0% {
        transform: translate(400px, 0);
    }
    100% {
        transform: translate(0, 0);
    }
}

@keyframes animation-3desm-nav-backdrop {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

._3desm {
    &__nav {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 170;

        &-backdrop {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            opacity: 0;
            @include background-color(bg-overlay);
            animation-name: animation-3desm-nav-backdrop;
            animation-duration: 0.15s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
        }

        &-panel {
            position: absolute;
            width: 100%;
            max-width: 400px;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            overflow-x: hidden;
        }

        &-header {
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }

        &-links {
            display: flex;
            flex-direction: column;
        }

        &-link {
            text-align: right;
            padding: 10px 10px;
            font-size: 25px;
            font-weight: 500;
            cursor: pointer;
            animation-name: animation-3desm-nav-link;
            animation-duration: 0.2s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;
            transform: translate(400px, 0);
            text-transform: uppercase;

            @include hover {
                @include color(main);
            }

            &:nth-child(1) {
                animation-delay: 0.06s;
            }

            &:nth-child(2) {
                animation-delay: 0.1s;
            }

            &:nth-child(3) {
                animation-delay: 0.14s;
            }
        }
    }
}
