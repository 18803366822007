._3desm {
    &__button,
    &__button-active {
        height: 40px;
        background-color: white;
        @include color(main);
        padding: 0 20px;
        border-radius: 20px;
        font-size: 16px;
        margin: 10px;
        text-transform: uppercase;
        @include ff-main;
        outline: none;
        cursor: pointer;
        border: none;
        @include box-shadow(1);
        transition: all 0.2s ease-out;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &--icon {
            width: 40px;
            flex: 0 0 40px;
            @include ff-icon;
            padding: 0;
            font-size: 20px;
            @include box-shadow(1);
        }

        &--secondary {
            @include color(black-1);

            @include hover {
                @include color(main);
            }
        }

        &__icon {
            @include ff-icon;
            padding: 0 0 0 10px;
            font-size: 20px;
        }

        &--small {
            height: 32px;
        }
    }

    &__button-active {
        background-color: $color-main;
        color: $color-main-c;
    }
}
