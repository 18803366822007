@import "../scss/variables";

._3desm {
    &__model-and-apartments {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: stretch;
        justify-content: flex-start;
        overflow: hidden;
        position: relative;

        @include mobile {
            flex-direction: column;
            // flex-wrap: wrap;
            height: unset;
        }

        &--compare,
        &--send-message,
        &--pages {
            @include mobile {
                max-width: 100vw;
                max-height: 100%;
                overflow: hidden;
            }
        }

        &__left {
            flex: 0 1 100%;
            height: 100%;
            position: relative;
            z-index: 1;

            @include mobile {
                flex: 0 0 100%;
                width: 100%;
            }
        }

        &__right {
            flex: 1 0 440px;
            height: 100%;
            position: relative;
            // box-shadow: $box-shadow-6;
            z-index: 2;
            @include box-shadow(3);

            @include mobile {
                flex: 0 0 100%;
            }
        }

        &__compare {
            position: absolute;
            z-index: 100;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            @include background-color(bg);
        }
    }
}
