@import "../../scss/variables";

._3desm {
    &__loader {
        width: 60px;
        height: 60px;
        display: flex;
        flex-wrap: wrap;

        &-circle {
            width: 50%;
            height: 50%;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;

            &::after {
                content: "";
                width: 100%;
                height: 100%;
                background: $color-main;
                position: absolute;
                // border-radius: 50%;
                animation-name: animation_3desm_loader_circle;
                animation-duration: 1.2s;
                animation-iteration-count: infinite;
            }

            &:nth-child(1) {
                &::after {
                    border-radius: 50% 0 0 0;
                }
            }

            &:nth-child(2) {
                &::after {
                    border-radius: 0 50% 0 0;
                    animation-delay: 0.3s;
                }
            }

            &:nth-child(3) {
                &::after {
                    border-radius: 0 0 0 50%;
                    animation-delay: 0.9s;
                }
            }

            &:nth-child(4) {
                &::after {
                    border-radius: 0 0 50% 0;
                    animation-delay: 0.6s;
                }
            }
        }
    }
}

@keyframes animation_3desm_loader_circle {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    75% {
        // transform: scale(0.7);
        opacity: 0.3;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
