@import "../../scss/variables";

._3desm {
    &__mobile-placeholder {
        font-family: "Montserrat", sans-serif;
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: white;

        &__bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.3;
            z-index: 0;
            background-size: cover;
            background-position: center center;
        }

        &__title {
            text-transform: uppercase;
            font-size: 40px;
            text-align: center;
            padding: 10px;
            font-weight: 700;
            z-index: 1;
        }

        ._3desm__button {
            border: 1px solid rgb(209, 142, 59);
            z-index: 2;
        }
    }
}
