@import "../../../scss/variables";

._3desm {
    &__field-radio-bar {
        width: auto;
        position: relative;
        padding: 5px 0;

        &__content {
            position: relative;
            display: flex;
            align-items: center;
            border-width: 1px;
            height: 30px;
            border-style: solid;
            @include border-color(grey-1);
            user-select: none;
            border-radius: 15px;
        }

        &__option {
            cursor: pointer;
            padding: 0 10px;
            font-size: 11px;
            text-transform: uppercase;
            height: 100%;
            display: flex;
            align-items: center;
            transition: color 0.2s ease-in-out;

            &:not(:nth-child(2)) {
                border-left-width: 1px;
                border-left-style: solid;
                @include border-side-color(left, grey-1);
            }

            &--active {
                @include color(main);
                border-left-color: transparent !important;

                & + ._3desm__field-radio-bar__option {
                    border-left-color: transparent !important;
                }
            }

            &-icon {
                font-size: 20px;
                margin-right: 5px;
                @include color(grey-5);

                ._3desm__field-radio-bar__option--active & {
                    @include color(main);
                }
            }

            @include breakpoint-sm-dn {
                font-size: 9px;
                padding: 0 5px;
            }
        }

        &__thumb {
            position: absolute;
            top: 0;
            height: 100%;
            border-style: solid;
            border-width: 1px;
            @include border-color(main);
            @include background-color(main-1);
            border-radius: 15px;
            transition: all 0.2s ease-in-out;
        }
    }
}
