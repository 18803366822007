@import "../../../../scss/variables";

._3desm {
    &__flat-slice-features {
        display: flex;
        flex-direction: column;
        flex: 1 0 300px;
        max-width: 400px;
        padding: 10px;
        font-size: 14px;

        &__list {
            display: flex;
            flex-direction: column;
            @include border(1, grey-2);
            padding: 5px 0;
        }

        &__item {
            display: flex;
            align-items: center;
            padding: 10px;

            &::before {
                content: "";
                width: 8px;
                height: 8px;
                @include background-color(main);
                margin-right: 10px;
                flex: 0 0 8px;
            }
        }
    }
}
