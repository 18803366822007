@import "../../scss/variables";

._3desm {
    &__compare-table {
        position: relative;
        height: 100%;
        // width: 100%;

        &__wrapper {
            position: relative;
            height: 100%;
            display: flex;
            // align-items: flex-start;
            // width: 100%;
        }

        &__table {
            border-spacing: 0;
            position: relative;
            min-height: 100%;
            @include box-shadow(4);
        }

        &__thead {
            z-index: 20;
        }

        &__th {
            font-size: 20px;
            // color: $color-black;
            @include color(black-1);
            padding: 20px 10px;
            text-align: center;
            position: sticky;
            z-index: 10;
            top: 0;
            // top: $header-height-sm * 2;
            // background-color: $color-grey-0;
            @include background-color(grey-0);
            border-bottom: 1px solid $color-grey-2;
            @include border-side-color(bottom, grey-2);

            @include breakpoint-md-dn {
                font-size: 16px;
            }

            // @media (min-width: $breakpoint-md) {
            //     top: 7rem;
            // }

            &:first-child {
                left: 0;
                z-index: 20;
            }

            &:not(:last-child) {
                border-right: 1px solid $color-grey-2;
            }

            &.darker {
                background-color: $color-grey-1;
            }
        }

        &__tbody {
            z-index: 1;
        }

        &__td {
            padding: 10px;
            text-align: center;
            font-size: 16px;
            @include background-color(grey-0);

            @include breakpoint-md-dn {
                font-size: 14px;
            }

            &:not(:last-child) {
                border-right: 1px solid $color-grey-2;
            }

            &--darker {
                @include background-color(grey-1);
            }

            ._3desm__compare-table__tr:nth-child(2n) & {
                @include background-color(grey-1);
            }

            @include hover-generic {
                ._3desm__compare-table__tr:hover & {
                    @include background-color(grey-3);
                }
            }

            &-first {
                text-align: right;
                font-weight: bold;
                position: sticky;
                left: 0;
                z-index: 10;
                padding: 10px;
                @include background-color(grey-0);
                border-right: 1px solid $color-grey-2;

                @include breakpoint-md-dn {
                    font-size: 14px;
                }

                ._3desm__compare-table__tr:nth-child(2n) & {
                    @include background-color(grey-1);
                }

                @include hover-generic {
                    ._3desm__compare-table__tr:hover & {
                        @include background-color(grey-3);
                    }
                }

                &--secondary {
                    font-weight: 400;
                }
            }
        }

        &__layout {
            width: 200px;
            height: 250px;
            object-fit: contain;
            margin: 0 10px;
        }

        &__buttons {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &__add-more-placeholder {
            flex: 1 0 400px;

            @include mobile {
                flex: 0 0 100vw;
            }
        }
    }
}
