@import "../../scss/variables";

._3desm {
    &__contact-form {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        @include background-color(bg-overlay);
        z-index: 150;
        display: flex;
        justify-content: flex-end;

        @include desktop {
            padding: 20px;
        }

        &__wrapper {
            @include background-color(bg);
            width: 100%;
            height: 100%;
            @include box-shadow(2);
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            overflow-x: hidden;
            overflow-y: auto;
            align-items: center;

            @include desktop {
                max-width: 400px;
            }
        }

        &__content {
            width: 100%;
            height: 100%;
            display: flex;
            max-width: 400px;
            flex-direction: column;
            padding-bottom: 10px;
            flex: 0 0 auto;
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include border-side(bottom, 1, grey-1);
            flex: 0 0 auto;
        }

        &__title {
            padding: 10px;
            text-transform: uppercase;
            font-weight: 500;
            font-size: 20px;
        }

        &__flats {
            display: flex;
            flex-direction: column;
            padding: 10px;
            width: 100%;

            &-title {
                font-size: 18px;
                text-transform: uppercase;
                font-weight: 500;
                padding-bottom: 5px;
            }
        }

        &__form {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 0 0 auto;
        }

        &__spacer {
            flex: 0 0 80px;
        }

        &__form-field {
            display: flex;
            flex-direction: column;
            padding: 10px 10px;
            width: 100%;

            &-title {
                text-transform: uppercase;
                font-size: 14px;
                padding-bottom: 5px;
            }

            &-input {
                @include border(1, main);
                height: 35px;
                padding: 0 5px;
                font-size: 16px;
                @include ff-main;
                @include autocompleteBackground(bg);

                &:focus {
                    outline: none;
                }
            }

            &-textarea {
                @include border(1, main);
                height: 150px;
                resize: none;
                padding: 5px;
                font-size: 16px;
                @include ff-main;

                &:focus {
                    outline: none;
                }
            }

            &-error {
                padding-top: 5px;
                font-size: 12px;
                @include color(error);
            }
        }

        &__form-checkbox {
            &-label {
                display: flex;
                position: relative;
                font-size: 12px;

                a {
                    @include color(black-1);
                    text-decoration: underline;
                }
            }

            &-input {
                flex: 0 0 30px;
                opacity: 0;
            }

            &-check {
                position: absolute;
                @include ff-icon;
                @include background-color(bg);
                @include border(1, main);
                left: 0;
                top: 0;
                width: 25px;
                height: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 22px;
                @include color(bg);
                transition: color 0.1s ease-out;

                ._3desm__contact-form__form-checkbox--active & {
                    @include color(main);
                }
            }
        }

        &__submit {
            flex: 0 0 40px;
            width: 60%;
            margin-top: 40px !important;
        }
    }
}
