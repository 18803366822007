@import "../../scss/variables";

._3desm {
    &__flat-media-wrapper {
        width: 100%;
        height: 100%;
        position: relative;

        &__elevation {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            &-img {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__3d {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            &-iframe {
                border: none;
                width: 100%;
                height: 100%;
            }
        }

        &__doll-house {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
}
