@import "../../scss/variables";

._3desm {
    &__location {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        @include background-color(bg);
        z-index: 20;
        display: flex;
        flex-direction: column;

        @include desktop {
            flex-direction: row;
        }

        &-map {
            position: relative;
            z-index: 1;

            @include mobile {
                width: 100%;
                height: 50vh;
                flex: 0 0 50vh;
            }
            @include desktop {
                flex: 1 0 200px;
                height: 100%;
            }

            &-container {
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 1;

                ._3desm__location--select-active & {
                    cursor: cell !important;
                }
            }
        }

        &-panel {
            display: flex;
            flex-direction: column;
            z-index: 2;
            @include box-shadow(4);

            @include mobile {
                width: 100%;
                min-height: 50vh;
            }
            @include desktop {
                flex: 0 0 320px;
            }
        }

        &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex: 0 0 60px;
            @include border-side(bottom, 1, grey-1);

            &-title {
                padding: 10px;
                font-size: 30px;
                text-transform: uppercase;
            }
        }

        &-content {
            flex: 1 0 200px;
            display: flex;
            flex-direction: column;
            overflow-x: hidden;
            overflow-y: auto;
        }

        &-subtitle {
            padding: 10px;
            // font-weight: 500;
            font-size: 20px;
        }
    }
}
